import React from 'react';
import {Box} from '@mui/material';
import DetectionFilter from '../components/DetectionFilter';
import DetectionTable from '../components/DetectionTable';


const DataList = () => {

  return <Box>
    <DetectionFilter />
    <DetectionTable />
  </Box>;

};


export default DataList;
