/**
 * Data describing measured phenomena. Fetched from backend on application load.
 */

import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {client} from '../util/apiClient';

const initialState = {
  data: [],   // the data for the table
  idToLabel: {},  // serves for fast translation fo class id to croatian label
  status: 'init'
};

export const fetchDetectionPhenomena = createAsyncThunk('phenomena/list', async () => {
  const response = await client.get('/phenomena/list');
  return response.data;
});


const detectionPhenomenaSlice = createSlice({
  name: 'detectionPhenomena',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDetectionPhenomena.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchDetectionPhenomena.fulfilled, (state, action) => {
        state.data = action.payload;

        const idToLabel = {};
        for (const d of action.payload) {
          idToLabel[d.id] = d.name;
        }
        state.idToLabel = idToLabel;

        state.status = 'ok';
      })
      .addCase(fetchDetectionPhenomena.rejected, (state, action) => {
        state.data = [];
        state.idToName = {};
        state.status = 'error';
      });
  }
});


export default detectionPhenomenaSlice.reducer;
