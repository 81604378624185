import React from 'react';
import {Box, Button} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import {GRID_LOCALE_HR} from '../locale/dataGridLocaleHR.js';


const AlarmList = () => {

  const navigate = useNavigate();

  const columns = [
    {
      field: 'name',
      headerName: 'Naziv',
      width: 200
    },
    {
      field: 'created',
      headerName: 'Datum nastanka',
      width: 250,
      editable: false,
    },
    {
      field: 'lastRaised',
      headerName: 'Datum zadnjeg alarma',
      width: 250,
      editable: false,
    }
  ];

  return<Box>
    <Box sx={{display: 'flex', paddingBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
      <Button
        onClick={() => navigate('/alarm/new')}
        variant="contained"
        sx={{fontSize: '1.2em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        Novi alarm
      </Button>
    </Box>

    <Box height={800}>
      <DataGrid
        rows={[]}
        localeText={GRID_LOCALE_HR}
        columns={columns}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={50}
      />

    </Box>;
  </Box>;
};


export default AlarmList;
