import { configureStore, combineReducers } from '@reduxjs/toolkit';
import detectPhenomenaReducer, { fetchDetectionPhenomena } from './detectionPhenomena.js';
import chartConfigReducer from './chartConfig.js';
import chartStylesReducer from './chartStyles.js';
import userDataReducer from './userData.js';
import detectionFilterReducer from './detectionFilter.js';
import authReducer from './auth.js';
import settingsUsersReducer from './settingsUsers.js';
import settingsLocationsReducer from './settingsSites.js';
import settingsOrganizationsReducer from './settingsOrganizations.js';
import settingsParcelsReducer from './settingsParcels.js';
import settingsRoversReducer from './settingsRovers.js';
import settingsSensorsReducer from './settingsSensors.js';


const allReducers = combineReducers({
  auth: authReducer,
  detectionPhenomena: detectPhenomenaReducer,
  chartConfig: chartConfigReducer,
  chartStyles: chartStylesReducer,
  userData: userDataReducer,
  detectionFilter: detectionFilterReducer,
  settingsUsers: settingsUsersReducer,
  settingsLocations: settingsLocationsReducer,
  settingsOrganizations: settingsOrganizationsReducer,
  settingsParcels: settingsParcelsReducer,
  settingsRovers: settingsRoversReducer,
  settingsSensors: settingsSensorsReducer,
});

const rootReducer = (state, action) => {
  // if (action.type == 'login')
  return allReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer
});



//called when user logs in or re-loads the page
export const preloadDataAuthenticated = () => {
  store.dispatch(fetchDetectionPhenomena());
};

export default store;
