import React, { useEffect, useRef } from 'react';
import { Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { compareUnordered } from 'util/arrayUtils';

const AdvTabs = props => {
  const { children, onTabValuesChange, ...otherProps } = props;

  const tabValuesRef = useRef([]);

  useEffect(() => {
    if (typeof onTabValuesChange === 'function') {
      // Iterate over the Tab children and get their value attributes
      const newTabValues = React.Children.map(children, (child) => child.props.value);

      if(!compareUnordered(newTabValues, tabValuesRef.current)) {
        tabValuesRef.current = newTabValues;
        // Call the callback function with the tab values
        onTabValuesChange(newTabValues);
      }
    }
  }, [children, onTabValuesChange]);

  return (
    <Tabs {...otherProps}>
      {children}
    </Tabs>
  );
};

AdvTabs.propTypes = {
  children: PropTypes.node.isRequired,
  onTabValuesChange: PropTypes.func,
};

AdvTabs.defaultProps = {
  onTabValuesChange: null
};

export default AdvTabs;