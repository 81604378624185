
export const GRID_LOCALE_HR = {
  // Root
  noRowsLabel: 'Nema podataka',
  noResultsOverlayLabel: 'Nema rezultata',
  errorOverlayDefaultLabel: 'Greška u usnimavanju podataka',

  // Density selector toolbar button text
  toolbarDensity: 'Visina retka',
  toolbarDensityLabel: 'Visina retka',
  toolbarDensityCompact: 'Kompaktna',
  toolbarDensityStandard: 'Standardna',
  toolbarDensityComfortable: 'Široka',

  // Columns selector toolbar button text
  toolbarColumns: 'Odabir stupaca',
  toolbarColumnsLabel: 'Sakrij ili otkrij stupce',

  // Filters toolbar button text
  toolbarFilters: 'Odabir redaka',
  toolbarFiltersLabel: 'Prikaži postavke za filtriranje redka',
  toolbarFiltersTooltipHide: 'Sakrij filtere',
  toolbarFiltersTooltipShow: 'Prikaži filtere',
  toolbarFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktivnih filtera` : `${count} aktivni filter`,

  // Quick filter toolbar field
  toolbarQuickFilterPlaceholder: 'Pretraži…',
  toolbarQuickFilterLabel: 'Pretraži',
  toolbarQuickFilterDeleteIconLabel: 'Počisti',

  // Export selector toolbar button text
  toolbarExport: 'Izvoz',
  toolbarExportLabel: 'Izvezi podatke',
  toolbarExportCSV: 'Preuzmi CSV datoteku',
  toolbarExportPrint: 'Ispiši',
  toolbarExportExcel: 'Preuzmi Excel datoteku',

  // Columns panel text
  columnsPanelTextFieldLabel: 'Pronađi stupac',
  columnsPanelTextFieldPlaceholder: 'Naziv stupca',
  columnsPanelDragIconLabel: 'Reorder column',
  columnsPanelShowAllButton: 'Prikaži sve',
  columnsPanelHideAllButton: 'Sakrij sve',

  // Filter panel text
  filterPanelAddFilter: 'Dodaj filter',
  filterPanelDeleteIconLabel: 'Izbriši',
  filterPanelLinkOperator: 'Logički operator',
  filterPanelOperator: 'Operacija',
  filterPanelOperatorAnd: 'I',
  filterPanelOperatorOr: 'ILI',
  filterPanelColumns: 'Stupac',
  filterPanelInputLabel: 'Vrijednost',
  filterPanelInputPlaceholder: 'Unesi vrijednost',

  // Filter operators text
  filterOperatorContains: 'sadrži',
  filterOperatorEquals: 'je jednak',
  filterOperatorStartsWith: 'počinje sa',
  filterOperatorEndsWith: 'završava sa',
  filterOperatorIs: 'je',
  filterOperatorNot: 'nije',
  filterOperatorAfter: 'je posliije',
  filterOperatorOnOrAfter: 'je na ili poslije',
  filterOperatorBefore: 'je prije',
  filterOperatorOnOrBefore: 'je na ili prije',
  filterOperatorIsEmpty: 'je prazno',
  filterOperatorIsNotEmpty: 'nije prazno',
  filterOperatorIsAnyOf: 'jedan od',

  // Filter values text
  filterValueAny: 'bilo koji',
  filterValueTrue: 'DA',
  filterValueFalse: 'NE',

  // Column menu text
  columnMenuLabel: 'Menu',
  columnMenuShowColumns: 'Prikaži stupce',
  columnMenuFilter: 'Filtriraj',
  columnMenuHideColumn: 'Sakrij',
  columnMenuUnsort: 'Isključi redanje',
  columnMenuSortAsc: 'Poredaj uzlazno',
  columnMenuSortDesc: 'Poredaj silazno',

  // Column header text
  columnHeaderFiltersTooltipActive: (count) =>
    count !== 1 ? `${count} aktivnih filtera` : `${count} aktivan filter`,
  columnHeaderFiltersLabel: 'Prikaži filtere',
  columnHeaderSortIconLabel: 'Poredaj',

  // Rows selected footer text
  footerRowSelected: (count) =>
    count !== 1
      ? `${count.toLocaleString()} odabranih redaka`
      : `${count.toLocaleString()} odabrani redak`,

  // Total row amount footer text
  footerTotalRows: 'Total Rows:',

  // Total visible row amount footer text
  footerTotalVisibleRows: (visibleCount, totalCount) =>
    `${visibleCount.toLocaleString()} od ${totalCount.toLocaleString()}`,

  // Checkbox selection text
  checkboxSelectionHeaderName: 'Odabrano',
  checkboxSelectionSelectAllRows: 'Odaberi sve retke',
  checkboxSelectionUnselectAllRows: 'Poništi sve odabire',
  checkboxSelectionSelectRow: 'Odaberi redak',
  checkboxSelectionUnselectRow: 'Poništi odabir retka',

  // Boolean cell text
  booleanCellTrueLabel: 'DA',
  booleanCellFalseLabel: 'NE',

  // Actions cell more text
  actionsCellMore: 'više',

  // Column pinning text
  pinToLeft: 'Prikači lijevo',
  pinToRight: 'Prikači desno',
  unpin: 'Prestani prikačivati',

  // Tree Data
  treeDataGroupingHeaderName: 'Grupiraj',
  treeDataExpand: 'vidi grane',
  treeDataCollapse: 'sakrij grane',

  // Grouping columns
  groupingColumnHeaderName: 'Grupiraj',
  groupColumn: (name) => `Grupiraj po ${name}`,
  unGroupColumn: (name) => `Prestani grupirati po ${name}`,

  // Master/detail
  detailPanelToggle: 'Prikaži/sakrij detalje',
  expandDetailPanel: 'Proširi',
  collapseDetailPanel: 'Sažmi',

  // Used core components translation keys
  MuiTablePagination: {
    labelDisplayedRows: ({ from, to, count }) =>
      `${from} - ${to} od ${count}`,
  },

  // Row reordering text
  rowReorderingHeaderName: 'Ponovno redanje redaka',

  // Aggregation
  aggregationMenuItemHeader: 'Agregacija',
  aggregationFunctionLabelSum: 'zbroj',
  aggregationFunctionLabelAvg: 'prosjek',
  aggregationFunctionLabelMin: 'min',
  aggregationFunctionLabelMax: 'maks',
  aggregationFunctionLabelSize: 'veličina',
};
