/**
 * Data pertaining to current user.
 *
 * TODO Meant to be fetched from the backend
 * NOTE sqlalchemy works with postgresql json capabilities so a lot of user data could just be stored as json objects.
 */

import {createSlice} from '@reduxjs/toolkit';
import {dateToStandardString, oneMonthAgo} from '../util/timeUtils';

const initialState = {

  dashboard: {

    /**
     * Default time for the dashboard items.
     *
     * NOTES:
     *   - currently default for whole dashboard per user, might be moved to app level
     *   - might be used to save last period user set?
     */
    time: {
      date_from: dateToStandardString(oneMonthAgo),
      date_to: dateToStandardString(new Date()),
      interval: 'week'
    },

    /**
     * Reference to style to use for victory charts. Lookup for store.chartStyles.
     */
    chartStyle: 'default',

    /**
     * Visualisations and tables to show to users on their dashboard. Each item consists of following mandatory fields
     * `id: string, type: string, title: string, desc: string`. Rest of the fields depend on type, current types are:
     *   - ChartAgg, additional fields: `chart_type: string, agg: [{agg_func: string, class_name: string}]`
     *   - TableAgg, additional fields: `agg: [{agg_func: string, class_name: string}]`
     *   - TableData, additional fields: `time: from - to`
     */
    items: [
      {
        id: 'red_apples',
        type: 'ChartAgg',
        title: 'Crvene jabuke',
        desc: 'Prosječan broj crvenih jabuka za četiri različite klase (A, B, C i nezrele) u odabranom razdoblju.',
        chart_type: 'Line',
        agg: [
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_red_A'},
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_red_B'},
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_red_C'},
          {agg_func: 'avg', class_name: 'apple_fruit_unripe_red'},
        ]
      },
      {
        id: 'green_apples',
        type: 'ChartAgg',
        label: 'Zelene jabuke',
        desc: 'Prosječan broj zelenih jabuka za četiri različite klase (A, B, C i nezrele) u odabranom razdoblju.',
        chart_type: 'Line',
        agg: [
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_A'},
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_B'},
          {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_C'},
          {agg_func: 'avg', class_name: 'apple_fruit_unripe_green'},
        ]
      },
      {
        id: 'traps',
        type: 'ChartAgg',
        label: 'Insekti u zamkama',
        desc: 'Prosječni brojevi insekata uhvaćenih u zamkama',
        chart_type: 'Line',
        agg: [
          {agg_func: 'avg', class_name: 'apple_trap_moth'},
          {agg_func: 'avg', class_name: 'apple_trap_leaf_miner'},
          {agg_func: 'avg', class_name: 'apple_trap_stink_bugs'}
        ]
      },
      {
        id: 'sickness',
        type: 'ChartAgg',
        label: 'Bolesti listova',
        desc: 'Prosječan broj listova s krastavosti ili pepelnicom.',
        chart_type: 'Line',
        agg: [
          {agg_func: 'avg', class_name: 'apple_fruit_scab'},
          {agg_func: 'avg', class_name: 'apple_leaf_mildew'}
        ]
      },
    ],
  }
};



const userDataSlice = createSlice({
  name: 'userData',
  initialState
});

export default userDataSlice.reducer;
