import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsParcels } from 'store/settingsParcels';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'name',
    headerName: 'Naziv',
    width: 200,
    editable: false,
  },
  {
    field: 'site.name',
    headerName: 'Lokacija',
    width: 150,
    editable: false,
    valueGetter: ({ row }) => {
      return row.site?.name || '';
    },
  },
];

const dataSelector = (state) => state.settingsParcels.data;
const statusSelector = (state) => state.settingsParcels.status;

const config = {
  columns,
  fetchActions: fetchSettingsParcels,
  dataSelector,
  statusSelector,
};

const ParcelsGrid = withEntityDataGrid(config);

const ParcelsManagement = props => {
  return <Box height={800}>
    <ParcelsGrid {...props} />
  </Box>;
};

export default ParcelsManagement;