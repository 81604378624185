import React, {useEffect} from 'react';
import {Box, Card, CardContent, List, ListItemButton, ListItemText, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';

import {chartConfigSetAgg} from '../store/chartConfig.js';


const saved = [
  {
    id: 'leaf_data',
    label: 'Listovi',
    desc: 'Podaci o rastu listova (Zdravi list, Zravi list - mladi i Mladi nerazvijeni list) u odabranom razdoblju',
    agg: [
      {agg_func: 'avg', class_name: 'apple_leaf_healthy', 'legend': 'Zdravi list'},
      {agg_func: 'avg', class_name: 'apple_leaf_healthy_juvenile', 'legend': 'Zdravi list - mladi'},
      {agg_func: 'avg', class_name: 'apple_undeveloped_leaf', 'legend': 'Mladi nerazvijeni list'}
    ]
  },
  {
    id: 'green_apples',
    label: 'Zelene jabuke',
    desc: 'Prosječan broj zelenih jabuka za četiri različite klase (A, B, C i nezrele) u odabranom razdoblju.',
    agg: [
      {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_A', 'legend': 'Zelene A'},
      {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_B', 'legend': 'Zelene B'},
      {agg_func: 'avg', class_name: 'apple_fruit_healthy_green_C', 'legend': 'Zelene C'},
      {agg_func: 'avg', class_name: 'apple_fruit_unripe_green', 'legend': 'Zelene nezrele'},
    ]
  },
  {
    id: 'traps',
    label: 'Insekti u zamkama',
    desc: 'Prosječni brojevi insekata uhvaćenih u zamkama',
    agg: [
      {agg_func: 'avg', class_name: 'apple_trap_moth', 'legend': 'Jabukov savijač'},
      {agg_func: 'avg', class_name: 'apple_trap_leaf_miner', 'legend': 'Miner okruglih mina'},
      {agg_func: 'avg', class_name: 'apple_trap_stink_bugs', 'legend': 'Stjenice'}
    ]
  },
  {
    id: 'sickness',
    label: 'Bolesti listova',
    desc: 'Prosječan broj listova s krastavosti ili pepelnicom.',
    agg: [
      {agg_func: 'avg', class_name: 'apple_fruit_scab', 'legend': 'Krastavost'},
      {agg_func: 'avg', class_name: 'apple_leaf_mildew', 'legend': 'Pepelnica'}
    ]
  },
];

const AggSavedSelect = () => {

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [currentDescription, setCurrentDescription] = React.useState(saved[selectedIndex].desc);

  const dispatch = useDispatch();

  // trick to run only once as per https://reactjs.org/docs/hooks-effect.html, used moving first option into store  // TODO not true, executes twice, no effect currently but still :P
  useEffect(() => {
    dispatch(chartConfigSetAgg(saved[selectedIndex].agg));
  // eslint-disable-next-line
  }, []);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setCurrentDescription(saved[index].desc);
    dispatch(chartConfigSetAgg(saved[index].agg));
  };

  return <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'stretch'}}>

    <Card sx={{flex: 1}}>
      <CardContent>

        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Naziv
        </Typography>

        <List component="nav">
          {saved.map((profile, index) => {
            return <ListItemButton
              key={profile.id}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index)}
            >
              <ListItemText primary={profile.label}/>
            </ListItemButton>;
          })
          }
        </List>

      </CardContent>
    </Card>

    <Card sx={{flex: 3}}>
      <CardContent>

        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Opis
        </Typography>

        <Typography>{currentDescription}</Typography>

      </CardContent>
    </Card>

  </Box>;
};


export default AggSavedSelect;
