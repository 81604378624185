import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsRovers } from 'store/settingsRovers';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'rover_code',
    headerName: 'Šifra',
    width: 150,
    editable: false,
  },
  {
    field: 'rover_version',
    headerName: 'Verzija',
    width: 150,
    editable: false,
  },
  {
    field: 'parcel.name',
    headerName: 'Voćnjak',
    width: 150,
    editable: false,
    valueGetter: ({ row }) => {
      return row.parcel?.name || '';
    },
  },
];

const dataSelector = (state) => state.settingsRovers.data;
const statusSelector = (state) => state.settingsRovers.status;

const config = {
  columns,
  fetchActions: fetchSettingsRovers,
  dataSelector,
  statusSelector,
};

const RoversGrid = withEntityDataGrid(config);

const RoversManagement = props => {
  return <Box height={800}>
    <RoversGrid {...props} />
  </Box>;
};

export default RoversManagement;