import React, {useCallback, useState, useMemo} from 'react';
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import {useSelector, useDispatch} from 'react-redux';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/hr';
import uniq from 'lodash/uniq';
import {detectionFilterSet, detectionFilterRemove} from '../store/detectionFilter';
import {dateToStandardString, oneMonthAgo, isValid} from '../util/timeUtils.js';
import DateFromToPicker from './common/DateFromToPicker';


const DetectionFilter = () => {

  const detectionPhenomena = useSelector((state) => state.detectionPhenomena.data);
  const phenomenaStatus = useSelector((state) => state.detectionPhenomena.status);
  const filterData = useSelector((state) => state.detectionFilter.filter);

  const dispatch = useDispatch();

  const [dateMin, setDateMin] = useState(filterData.find(i => i.type === 'minPointTime')?.value || oneMonthAgo);
  const [dateMax, setDateMax] = useState(filterData.find(i => i.type === 'maxPointTime')?.value || new Date());
  const [selectionFilter, setSelectionFilter] = useState({});
  const [className, setClassName] = useState(filterData.find(i => i.type === 'className')?.value);


  const setFilterData = useCallback((filterType, filterValue) => {
    switch (filterType) {
      case 'minPointTime':
        setDateMin(filterValue);
        filterValue = dateToStandardString(filterValue);
        dispatch(detectionFilterSet({type: filterType, value: filterValue}));
        break;
      case 'maxPointTime':
        setDateMax(filterValue);
        filterValue = dateToStandardString(filterValue);
        dispatch(detectionFilterSet({type: filterType, value: filterValue}));
        break;
      case 'minValue':
      case 'maxValue':
        filterValue = Number(filterValue);
        dispatch(detectionFilterSet({type: filterType, value: filterValue}));
        break;
      case 'hasImage':
        if (filterValue) {
          dispatch(detectionFilterSet({type: filterType, value: filterValue}));
        } else {
          // do not set filter to null, remove it (deselected hasImage does not mean "does not have image")
          dispatch(detectionFilterRemove(filterType));
        }
        break;
      case 'className':
        setClassName(filterValue);
        if (filterValue) {
          dispatch(detectionFilterSet({type: filterType, value: filterValue}));
        } else {
          // do not set filter to null, remove it
          dispatch(detectionFilterRemove(filterType));
        }
        break;
      case 'classCategory':
        setSelectionFilter(filterValue);
        if (filterValue.category) {
          dispatch(detectionFilterSet({type: filterType, value: filterValue.category}));
        } else {
          // do not set filter to null, remove it
          dispatch(detectionFilterRemove(filterType));
        }
        break;
      case 'classModel':
        setSelectionFilter(filterValue);
        if (filterValue.analytic_model) {
          dispatch(detectionFilterSet({type: filterType, value: filterValue.analytic_model}));
        } else {
          // do not set filter to null, remove it
          dispatch(detectionFilterRemove(filterType));
        }
        break;
      default:
        console.error(`Unknown filter type: ${filterType}`);
        break;
    }
  }, [dispatch]);


  // NOTE duplicated code from AggSeriesInput, might generalize to own component later on if usage can be properly split
  const phenomenaSelectionList = useMemo(() => {
    return detectionPhenomena.filter((p)=>{
      let exclude = false;
      Object.keys(selectionFilter).every((key) => {
        if (selectionFilter[key]) {
          if (p[key] !== selectionFilter[key]) {
            exclude = true;
            return false;
          }
        }
        return true;
      });
      return !exclude;
    });
  }, [detectionPhenomena, selectionFilter]);

  const selectedClass = useMemo(() => {
    if(className){
      return phenomenaSelectionList.find(p => p.id === className) || null;
    } else {
      return null;
    }
  }, [phenomenaSelectionList, className]);
  //---

  if (phenomenaStatus === 'loading') {
    return <Box sx={{display: 'flex'}}>
      <CircularProgress/>
    </Box>;
  }

  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'space-between'
  }}>

    <DateFromToPicker
      valueFrom={dateMin}
      valueTo={dateMax}
      onChangeFrom={(newValue) => {
        if(!newValue || !isValid(newValue)) {
          return;
        }
        return setFilterData('minPointTime', newValue);
      }}
      onChangeTo={(newValue) => {
        if(!newValue || !isValid(newValue)) {
          return;
        }
        return setFilterData('maxPointTime', newValue);
      }}
    />

    <Box component="form" sx={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: 2
    }}>
      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Kategorija" />}
        options={uniq(detectionPhenomena.map((p) => p.category).filter((p) => !!p))}
        onChange={(event, value) => {
          setFilterData('classCategory', {...selectionFilter, category: value});
        }}
        style={{ width: 200 }}

      />

      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Analitički model" />}
        options={uniq(detectionPhenomena.map((p) => p.analytic_model).filter((p) => !!p))}
        onChange={(event, value) => {
          setFilterData('classModel', {...selectionFilter, analytic_model: value});
        }}
        style={{ width: 200 }}
      />

      <Autocomplete
        value={selectedClass}
        getOptionLabel={(option) => option.name || option.id}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Pojava" />}
        options={phenomenaSelectionList}
        onChange={(e,v) => setFilterData('className', v?.id)}
        style={{ width: 300 }}
        noOptionsText="Nema Pojave koja odgovara kriterijima"
      />
    </Box>

    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: 2
    }}>

      <TextField
        id="min-value"
        type="number"
        label="Min vrijednost"
        variant="outlined"
        defaultValue={filterData.find(i => i.type === 'minValue')?.value}
        onChange={ (e) => setFilterData('minValue', e.target.value) }
        sx={{width: 200}}
      />
      <TextField
        id="max-value"
        type="number"
        label="Maks vrijednost"
        variant="outlined"
        defaultValue={filterData.find(i => i.type === 'maxValue')?.value}
        onChange={ (e) => setFilterData('maxValue', e.target.value) }
        sx={{width: 200}}
      />

    </Box>

    <FormControlLabel
      control={<Checkbox />}
      label='Samo sa slikom'
      checked={filterData.find(i => i.type === 'hasImage')?.value || false}
      onChange={ (e) => setFilterData('hasImage', e.target.checked) }
      sx={{marginTop: 2}}
    />

  </Box>;

};


export default DetectionFilter;
