import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsUsers } from 'store/settingsUsers';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'username',
    headerName: 'Korisničko ime',
    width: 150,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Ime',
    width: 150,
    editable: false,
  },
  {
    field: 'surname',
    headerName: 'Prezime',
    width: 150,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Elektronička pošta',
    width: 150,
    editable: false,
  },
  {
    field: 'organization.display_name',
    headerName: 'Organizacija',
    width: 150,
    editable: false,
    valueGetter: ({ row }) => {
      return row.organization?.display_name || '';
    },
  },
];

const dataSelector = (state) => state.settingsUsers.data;
const statusSelector = (state) => state.settingsUsers.status;

const config = {
  columns,
  fetchActions: fetchSettingsUsers,
  dataSelector,
  statusSelector,
};

const UsersGrid = withEntityDataGrid(config);

const UsersManagement = props => {
  return <Box height={800}>
    <UsersGrid {...props} />
  </Box>;
};

export default UsersManagement;