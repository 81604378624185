import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsSites } from 'store/settingsSites';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'name',
    headerName: 'Naziv',
    width: 200,
    editable: false,
  },
  {
    field: 'address',
    headerName: 'Adresa',
    width: 250,
    editable: false,
  },
  {
    field: 'lat',
    headerName: 'Zemljopisna širina',
    width: 200,
    editable: false,
  },
  {
    field: 'lon',
    headerName: 'Zemljopisna dužina',
    width: 200,
    editable: false,
  },
  {
    field: 'organization.display_name',
    headerName: 'Organizacija',
    width: 150,
    editable: false,
    valueGetter: ({ row }) => {
      return row.organization?.display_name || '';
    },
  },
];

const dataSelector = (state) => state.settingsLocations.data;
const statusSelector = (state) => state.settingsLocations.status;

const config = {
  columns,
  fetchActions: fetchSettingsSites,
  dataSelector,
  statusSelector,
};

const SitesGrid = withEntityDataGrid(config);

const SitesManagement = props => {
  return <Box height={800}>
    <SitesGrid {...props} />
  </Box>;
};

export default SitesManagement;