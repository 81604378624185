import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from 'util/apiClient';
// @ts-ignore


const initialState = {
  loading: false,
  user: {}, // for user object
  // userToken: null, // for storing the JWT
  error: ''
};



export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {

      const data = await client.post('/login', { username, password });
      // store user's token in local storage
      // localStorage.setItem('userToken', data.access_token);


      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginWithCookies = createAsyncThunk(
  'auth/loginWithCookies',
  async () => {
    const data = await client.get('/login');
    // store user's token in local storage
    // localStorage.setItem('userToken', data.access_token);
    return data;
  }
);
loginWithCookies.onFulfilled = (state, action) => {
  state.loading = false;
  state.user = action.payload.user;
};
loginWithCookies.onRejected = (state, action) => {
  state.loading = false;
  state.error = action.payload;
  state.user = {};
};
loginWithCookies.onPending = (state) => {
  state.loading = true;
  state.error = null;
};




export const userLogout = createAsyncThunk(
  'auth/logout',
  async () => {
    const data = await client.post('/logout');
    // remove user's token from local storage
    // localStorage.removeItem('userToken');
    return data;
  }
);




const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        // @ts-ignore
        state.error = action.payload;
      })

      .addCase(userLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.loading = initialState.loading;
        state.user = initialState.user;
        // state.userToken = initialState.userToken;
        state.error = initialState.error;
      })
      .addCase(userLogout.rejected, (state) => {
        //if API call fails still logout frontend
        state.loading = initialState.loading;
        state.user = initialState.user;
        // state.userToken = initialState.userToken;
        state.error = initialState.error;
      })
      // @ts-ignore
      .addCase(loginWithCookies.fulfilled, loginWithCookies.onFulfilled)
      .addCase(loginWithCookies.rejected, loginWithCookies.onRejected)
      .addCase(loginWithCookies.pending, loginWithCookies.onPending)
  }
});

export default auth.reducer;
