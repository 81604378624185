import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsSensors } from 'store/settingsSensors';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'system_id',
    headerName: 'Sistemska šifra',
    width: 200,
    editable: false,
  },
  {
    field: 'system',
    headerName: 'Sistem',
    width: 150,
    editable: false,
  },
  {
    field: 'system_uri',
    headerName: 'Adresa (URI) sistema',
    width: 200,
    editable: false,
  },
  {
    field: 'lat',
    headerName: 'Zemljopisna širina',
    width: 200,
    editable: false,
  },
  {
    field: 'lon',
    headerName: 'Zemljopisna duljina',
    width: 200,
    editable: false,
  },
  {
    field: 'parcel.name',
    headerName: 'Voćnjak',
    width: 150,
    editable: false,
    valueGetter: ({ row }) => {
      return row.parcel?.name || '';
    },
  },
];

const dataSelector = (state) => state.settingsSensors.data;
const statusSelector = (state) => state.settingsSensors.status;

const config = {
  columns,
  fetchActions: fetchSettingsSensors,
  dataSelector,
  statusSelector,
};

const SensorsGrid = withEntityDataGrid(config);

const SensorsManagement = props => {
  return <Box height={800}>
    <SensorsGrid {...props} />
  </Box>;
};

export default SensorsManagement;