export const getShortName = user => {
  if(!user) {
    return '(Obrisano)';
  }

  let result = '';

  if(user.name) {
    result = user.name;
  }

  if(user.surname) {
    if(result.length) {
      result += ' ';
    }
    result += user.surname[0] + ".";
  }

  return result || '-';
};

export const getFullName = (user, reverseNames) => {
  if(!user) {
    return '(Korisnik obrisan)';
  }

  let result = '';

  if(reverseNames && user.surname) {
    result = user.surname;
  }

  if(user.name) {
    if (result.length) {
      result += ' ';
    }
    result += user.name;
  }

  if(!reverseNames && user.surname) {
    if (result.length) {
      result += ' ';
    }
    result += user.surname;
  }

  return result || '-';
};
