import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { loginWithCookies } from 'store/auth';



const ProtectedRoute = ({ children }) => {

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  if (!user?.id) {

    if (Cookies.get('csrf_access_token')) {
      // try to resume session
      console.debug('trying to resume session...');
      dispatch(loginWithCookies());
    }


    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
