import React from 'react';
import Router from './Router';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from 'styles/theme';
import GlobalErrorBoundary from 'pages/GlobalError';
import store from 'store';


function App() {
  return (
    <GlobalErrorBoundary>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            {/*from MUI: CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline/>
            <HashRouter>
              <Router/>
            </HashRouter>
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </GlobalErrorBoundary>
  );
}

export default App;
