import React, {useState, useMemo, useCallback} from 'react';
import {Box, Button, CircularProgress, Link} from '@mui/material';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector, GridToolbarExport
} from '@mui/x-data-grid';
import {useDispatch, useSelector} from 'react-redux';
import {GRID_LOCALE_HR} from '../locale/dataGridLocaleHR.js';
import {detectionSortClear, detectionSortSetSingle} from '../store/detectionFilter';
import {dateToStandardString, dateToStandardTime} from '../util/timeUtils.js';
import {client} from '../util/apiClient.js';
import AppConfig from 'AppConfig';


const DetectionTable = () => {

  const idToLabel = useSelector((state) => state.detectionPhenomena.idToLabel);
  const queryFilter = useSelector((state) => state.detectionFilter.filter);
  const querySort = useSelector((state) => state.detectionFilter.sort);
  const phenomenaStatus = useSelector((state) => state.detectionPhenomena.status);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const onFetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await client.post(
        'detections/list?limit=1000',
        {
          filter: queryFilter,
          sort: querySort
        }
      );

      if (response.success === false) {
        return;
      }

      const rowData = [];
      for (const d of response.data) {
        const t = new Date(d.detection_point.time);
        rowData.push({
          id: d.id,
          class_name: idToLabel[d.class_name] || d.class_name,
          value: d.value,
          image: d.detection_point.hasImage ? `${AppConfig.backendUrl}/detections/image?id=${d.id}` : null,
          lat: d.detection_point.lat,
          lon: d.detection_point.lon,
          camera_code: d.camera_code,
          createdDate: dateToStandardString(t),
          createdTime: dateToStandardTime(t)
        });
      }
      // set state with the result
      setRows(rowData);
    } catch(e) {
      return false;
    }

    setIsLoading(false);
  }, [queryFilter, querySort, idToLabel]);

  const handleSortModelChange = useCallback(async (sortModel) => {

    if (!sortModel || sortModel.length === 0) {
      dispatch(detectionSortClear());
      await onFetchData();
      return;
    }

    const field = sortModel[0].field;
    let column;
    switch (field) {
      case 'value':
      case 'lat':
      case 'lon':
        column = field;
        break;
      case 'id':
        column = 'detection_values.id';
        break;
      default:
        break;
    }
    if (column) {
      dispatch(detectionSortSetSingle({column: column, order: sortModel[0].sort}));
      await onFetchData();
    }
  }, [onFetchData, dispatch]);


  const columns = useMemo( () => [
    {
      field: 'id',
      headerName: 'Šifra',
      width: 100,
      // TODO details view
      // renderCell: (params) => (
      //   params.value && <Link href={params.value} target="_blank">{params.value}</Link>
      // )
    },
    {
      field: 'class_name',
      headerName: 'Klasa',
      width: 200,
      editable: false,
    },
    {
      field: 'value',
      headerName: 'Vrijednost',
      width: 140,
      editable: false,
    },
    {
      field: 'image',
      headerName: 'Slika',
      width: 100,
      renderCell: (params) => (
        params.value && <Link href={params.value} target="_blank">Vidi sliku</Link>
      )
    },
    {
      field: 'createdDate',
      headerName: 'Datum',
      width: 130
    },
    {
      field: 'createdTime',
      headerName: 'Vrijeme',
      width: 130
    },
    {
      field: 'lat',
      headerName: 'LAT',
      width: 100
    },
    {
      field: 'lon',
      headerName: 'LON',
      width: 100
    },
    {
      field: 'camera_code',
      headerName: 'Kamera',
      width: 100
    }
  ], []);


  const getToolbar = () => <GridToolbarContainer>
    <GridToolbarColumnsButton />
    <GridToolbarDensitySelector />
    <GridToolbarExport />
  </GridToolbarContainer>;


  if (phenomenaStatus === 'loading') {
    return <Box sx={{display: 'flex'}}>
      <CircularProgress />
    </Box>;
  }

  return <Box>

    <Box sx={{display: 'flex', paddingBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
      <Button
        onClick={onFetchData}
        variant="contained"
        disabled={isLoading}
        sx={{fontSize: '1.2em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
      >
        Dohvati podatke
      </Button>
    </Box>

    <Box sx={{marginTop: 5}}>
      <Box height={700}>
        <DataGrid
          components={{
            Toolbar: getToolbar
          }}
          rows={rows}
          localeText={GRID_LOCALE_HR}
          columns={columns}
          rowsPerPageOptions={[10, 20, 50, 100]}
          loading={isLoading}

          // sortingMode="server"
          // onSortModelChange={handleSortModelChange}

          // workaround for missing key in localeText
          componentsProps={{
            pagination: {
              labelRowsPerPage: 'Redaka po stranici:'
            }
          }}
        />
      </Box>
    </Box>;

  </Box>;

};


export default DetectionTable;
