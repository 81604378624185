import React from 'react';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation, NavLink as RouterLink} from 'react-router-dom';

// @mui
import {styled, alpha} from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  useMediaQuery,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton
} from '@mui/material';

import {navMenuConfig as navConfig} from 'Router';
import LogoPng from '../assets/images/agriart-logo.png';
import AvatarImage from '../assets/images/default-avatar.jpg';
import { useSelector } from 'react-redux';
import { getFullName } from 'util/formatUtils';


const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({openNav, onCloseNav}) {
  const {pathname} = useLocation();

  const isDesktop = useMediaQuery('(min-width: 1224px)');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const userData = useSelector(state => state.auth.user);
  const userDataStatus = useSelector(state => state.auth.status);

  const renderContent = (
    <Container
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        },
      }}
    >
      <Box sx={{px: 2.5, py: 3, display: 'inline-flex'}}>
        <img width={200} src={LogoPng} alt="logo"/>
      </Box>

      <Box sx={{mb: 5, mx: 2.5}}>
        <Link underline="none">
          <StyledAccount>
            <Avatar alt="photoURL" src={AvatarImage} />

            <Box sx={{ml: 2}}>
              <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                {getFullName(userData)}
              </Typography>

              <Typography variant="body2" sx={{color: 'text.secondary'}}>
                {userData?.organization?.display_name}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      {/* <NavSection data={navConfig} /> */}
      <List disablePadding sx={{p: 1}}>
        {navConfig.map((item, index) => (
          <ListItemButton component={RouterLink} to={item.path} key={item.path}>
            <ListItemAvatar>
              <Avatar>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.title} secondary={item.subtitle}/>

          </ListItemButton>
        ))}
      </List>

      <Box sx={{flexGrow: 1}}/>
    </Container>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV_WIDTH},
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {width: NAV_WIDTH},
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
