import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import {Link} from 'react-router-dom';
import {Box, Button, Container, Typography} from '@mui/material';

// TODO: pick a rotten apple image
import OoopsErrorImage from '../assets/images/agriart-logo.png';


export default class GlobalError extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>
        <Helmet>
          <title>Something went wrong </title>
        </Helmet>

        <Container>
          <Typography variant="h3" paragraph>
            Sorry, Something went wrong!
          </Typography>

          <Typography sx={{color: 'text.secondary'}}>
            There was an unexpected error. Please try again later.
          </Typography>

          <Typography variant="caption">
            {/* TODO: show error details only in debug */}
            {this.state.error.message}
            {this.state.error.stack}
          </Typography>

          <Box
            component="img"
            src={OoopsErrorImage}
            sx={{height: 260, mx: 'auto', my: {xs: 5, sm: 10}}}
          />

          <Button to="/" size="large" variant="contained" component={Link}>
            Go to Home
          </Button>

        </Container>
      </>;
    }

    return this.props.children;
  }
}

GlobalError.propTypes = {
  children: PropTypes.node
};
