import {format, sub, isValid as dateFnsIsValid } from 'date-fns';


/***
 * Converts a string to a date format used by backend api, redux state and components.
 * @param {Date} date
 * @returns {string}
 */
export const dateToStandardString = (date) => {
  return isValid(date) && format(date, 'yyyy-MM-dd');
};

export const dateToStandardTime = (date) => {
  return isValid(date) && format(date, 'hh:mm');
};

export const oneMonthAgo = sub(new Date(), {months: 1});

export const isValid = (date) => {
  return dateFnsIsValid(date);
};
