import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import {
  Container,
  Typography,
  Stack,
  TextField,
  FormLabel,
  Link,
} from '@mui/material';

import Grid from '@mui/material/Grid';


import React, { useCallback, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { userLogin } from 'store/auth';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import AgriArtLogo from '../assets/images/agriart-logo.png';
import HrFlag from '../assets/images/HR-flag.png';
import EuFlag from '../assets/images/EU-flag.png';
import EsiLogo from '../assets/images/ESI-logo.png';
import OpkkLogo from '../assets/images/OPKK-logo.png';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function LoginPage() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = useCallback((data) => {
    dispatch(userLogin(data));
  }, [dispatch]);

  useEffect(() => {
    if (authState.user.id) {
      navigate('/', { replace: true });
    }
  }, [authState, navigate]);


  return (
    <>
      <Helmet>
        <title> Agriart Prijava </title>
      </Helmet>

      <Grid container flex={1} sx={{height:'100vh', display:'flex'}}>

        <Grid item xs={12} md={6} lg={7}>
          <StyledContent>
            <Img
              src={AgriArtLogo}
              alt="agriart"
            />
          </StyledContent>
        </Grid>

        <Grid item xs={12} md={6} lg={5} sx={{display:'flex'}}>
          <Container>
            <StyledContent>
              <Typography variant="h4" gutterBottom>
                Prijava
              </Typography>


              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <TextField
                    name="username"
                    autoComplete='username'
                    label="Korisničko ime"
                    {...register('username'
                      , {required:true}
                    )}
                    autoFocus
                  />

                  <TextField
                    name="password"
                    autoComplete='current-password'
                    label="Lozinka"
                    type="password"
                    {...register('password'
                      , {required:true}
                    )}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSubmit(onSubmit)();
                      }
                    }}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    //         {/* <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} /> */}
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Stack>

                {/*
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                >
                  <Checkbox name="remember" label="Remember me" />
                  <Link variant="subtitle2" underline="hover">
                    Forgot password?
                  </Link>
                </Stack> */}


                <LoadingButton
                  sx={{mt: 5}}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  // onClick={handleSubmit(onSubmit)}
                  disabled={watch('username') === '' || watch('password') === ''}
                  loading={authState.loading}
                >
                  Potvrdi
                </LoadingButton>
              </form>


              <FormLabel error={true} sx={{my:2}}>
                {authState.error}
              </FormLabel>


              {/* <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  OR
                </Typography>
              </Divider>
              <Typography variant="body2" sx={{ mb: 5 }}>
                Don’t have an account? {''}
                <Link variant="subtitle2">Get started</Link>
              </Typography> */}
            </StyledContent>
          </Container>
        </Grid>

        <Grid item xs={12}>
          <Container>
            <Grid item xs={12} sx={{justifyContent:'center', display:'flex'}}>
              <Typography variant='caption' >EUROPSKA UNIJA &bull; PROJEKT SUFINANCIRALA EUROPSKA UNIJA IZ EUROPSKOG FONDA ZA REGIONALNI RAZVOJ</Typography>
            </Grid>

            <Grid item container direction={'row'} sx={{paddingX:10}} >
              <Grid item xs={6} md={3} height={150} ><Img src={HrFlag} alt='HR zastava' /> </Grid>
              <Grid item xs={6} md={3} height={150} ><Img src={EsiLogo} alt='EU strukturni i investicijski fondovi logo' /> </Grid>
              <Grid item xs={6} md={3} height={150} ><Img src={EuFlag} alt='EU zastava' /> </Grid>
              <Grid item xs={6} md={3} height={150} ><Img src={OpkkLogo} alt='Konkurentnost i kohezija logo' /> </Grid>
            </Grid>
            <Grid item xs={12} sx={{justifyContent:'center', display:'flex'}}>


              <Typography variant='caption' >
                Stranica projekta za više informacija: &nbsp;
                <Link href='https://www.agramservis.hr/eu-fondovi/agriart/' target='_blank'>https://www.agramservis.hr/eu-fondovi/agriart/</Link>
              </Typography>

            </Grid>
          </Container>
        </Grid>


      </Grid>
    </>
  );
}
