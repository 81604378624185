import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {useSelector} from 'react-redux';
import uniq from 'lodash/uniq';


const aggregations = [
  {id: 'avg', name: 'Prosjek'},
  {id: 'sum', name: 'Zbroj'},
  {id: 'min', name: 'Minimum'},
  {id: 'max', name: 'Maksimum'}
];

const comparisons = [
  {id: 'equal', name: 'Jednako'},
  {id: 'greater', name: 'Veće od'},
  {id: 'lesser', name: 'Manje od'},
  {id: 'between', name: 'Između'}
];


const AlarmInput = (props) => {

  const detectionPhenomena = useSelector((state) => state.detectionPhenomena.data);
  const phenomenaStatus = useSelector((state) => state.detectionPhenomena.status);

  const [aggregation, setAggregation] = useState('avg');
  const [comparison, setComparison] = useState('avg');

  const [selectionFilter, setFilter] = useState({});
  const [className, setClassName] = useState();

  const handleAggSelect = (event) => {
    setAggregation(event.target.value);
    // dispatch(chartConfigChangeAgg({index: props.index, key: 'agg_func', value: event.target.value}));
  };

  const handleComparisonSelect = (event) => {
    setComparison(event.target.value);
    // dispatch(chartConfigChangeAgg({index: props.index, key: 'agg_func', value: event.target.value}));
  };

  const handlePhenomenaSelect = useCallback((val) => {
    setClassName(val);
    // dispatch(chartConfigChangeAgg({index: props.index, key: 'class_name', value: val}));
  }, []);  // dispatch, props.index

  const phenomenaSelectionList = useMemo(() => {
    return detectionPhenomena.filter((p)=>{
      let exclude = false;
      Object.keys(selectionFilter).every((key) => {
        if (selectionFilter[key]) {
          if (p[key] !== selectionFilter[key]) {
            exclude = true;
            return false;
          }
        }
        return true;
      });
      return !exclude;
    });
  }, [detectionPhenomena, selectionFilter]);

  const selectedClass = useMemo(() => {
    if(className){
      return phenomenaSelectionList.find(p => p.id === className) || null;
    } else {
      return null;
    }
  }, [phenomenaSelectionList, className]);


  if (phenomenaStatus === 'loading') {
    return <Box sx={{display: 'flex'}}>
      <CircularProgress/>
    </Box>;
  }

  return <Box  sx={{marginTop: 5, border: '1px dashed grey', padding: 2}}>

    <Typography sx={{fontWeight: 'bold'}}>Uvjet {props.index + 1}</Typography>

    <Box component="form" sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Typography>U zadnjih</Typography>
      <TextField
        id="n-days"
        type="number"
        label="N"
        variant="outlined"
        // defaultValue={filterData.find(i => i.type === 'maxValue')?.value}
        // onChange={ (e) => setFilterData('maxValue', e.target.value) }
        sx={{width: 100, marginRight: '5px', marginLeft: '5px'}}
      />
      <Typography>dana</Typography>
      {props.index > 0 && <FormControlLabel
        control={<Checkbox />}
        label='prije prošlog uvjeta'
        // checked={filterData.find(i => i.type === 'hasImage')?.value || false}
        // onChange={ (e) => setFilterData('hasImage', e.target.checked) }
        sx={{marginLeft: '5px'}}
      />}
    </Box>

    <Box component="form" sx={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: 2
    }}>

      <TextField
        id="agg-select"
        select
        label="Operacija"
        value={aggregation}
        onChange={handleAggSelect}
        sx={{width: 130, marginRight: '3px'}}
      >
        {aggregations.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>


      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Kategorija" />}
        options={uniq(detectionPhenomena.map((p) => p.category).filter((p) => !!p))}
        onChange={(event, value) => {
          setFilter({...selectionFilter, category: value});
        }}
        style={{ width: 200 }}

      />

      <Autocomplete
        renderInput={(params) => <TextField {...params} label="Analitički model" />}
        options={uniq(detectionPhenomena.map((p) => p.analytic_model).filter((p) => !!p))}
        onChange={(event, value) => {
          setFilter({...selectionFilter, analytic_model: value});
        }}
        style={{ width: 200 }}
      />

      <Autocomplete
        value={selectedClass}
        getOptionLabel={(option) => option.name || option.id}
        // isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label="Pojava" />}
        options={phenomenaSelectionList}
        onChange={(e,v) => handlePhenomenaSelect(v?.id)}
        style={{ width: 300 }}
        noOptionsText="Nema Pojave koja odgovara kriterijima"
      />
    </Box>

    <Box component="form" sx={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: 2,
      alignItems: 'center',
      justifyContent: 'center'
    }}>

      <TextField
        id="comp-select"
        select
        label="Operator"
        value={comparison}
        onChange={handleComparisonSelect}
        sx={{width: 150, marginRight: 4}}
      >
        {comparisons.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="first-value"
        type="number"
        label="Vrijednost"
        variant="outlined"
        // defaultValue={filterData.find(i => i.type === 'maxValue')?.value}
        // onChange={ (e) => setFilterData('maxValue', e.target.value) }
        sx={{width: 150}}
      />

      {comparison === 'between' && <>
        <Typography sx={{marginLeft: 2, marginRight: 2}}>i</Typography>
        <TextField
          id="first-value"
          type="number"
          label="Vrijednost"
          variant="outlined"
          // defaultValue={filterData.find(i => i.type === 'maxValue')?.value}
          // onChange={ (e) => setFilterData('maxValue', e.target.value) }
          sx={{width: 150}}
        />
      </>}

    </Box>
  </Box>;
};


AlarmInput.propTypes = {
  index: PropTypes.number.isRequired
};


const AlarmForm = () => {

  const [index, setIndex] = useState(0);
  const [inputChildren, setInputChildren] = useState([<AlarmInput key={`agg_input_${index}`} index={index} />]);

  const onAddChild = useCallback(
    () => {
      setInputChildren([...inputChildren,  <AlarmInput key={`agg_input_${index + 1}`} index={index + 1} />]);
      setIndex(index + 1);
      // dispatch(chartConfigPushAgg({agg_func: 'avg', class_name: null}));
    }, [inputChildren, index]
  );

  const onRemoveChild = useCallback(
    () => {
      setInputChildren(inputChildren.slice(0, -1));
      setIndex(index - 1);
      // dispatch(chartConfigPopAgg());
    }, [inputChildren, index]
  );


  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

    <TextField
      id="alarm-name"
      label="Naziv alarma"
      variant="outlined"
      // defaultValue={filterData.find(i => i.type === 'maxValue')?.value}
      // onChange={ (e) => setFilterData('maxValue', e.target.value) }
      sx={{marginBottom: 5}}
      fullWidth
    />

    <Box sx={{ width: '100%' }}>
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          disabled={index >= 5}
          onClick={onAddChild}
          sx={{fontSize: '1.1em', fontWeight: 'bold'}}
        >
          +
        </Button>

        <Button
          variant="outlined"
          onClick={onRemoveChild}
          disabled={index < 1}
          sx={{fontSize: '1.1em', fontWeight: 'bold'}}
        >
          -
        </Button>
      </Stack>
    </Box>


    <Box sx={{ width: '100%' }}>
      <Stack direction="column" spacing={2} sx={{marginTop: '20px'}}>
        {inputChildren}
      </Stack>
    </Box>

  </Box>;
};

export default AlarmForm;
