// components/EntityDataGrid.js
import React, { useEffect } from 'react';
import { GRID_LOCALE_HR } from 'locale/dataGridLocaleHR';
import { Box, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';

const withEntityDataGrid = props => {
  const { columns, fetchActions, dataSelector, statusSelector, ...otherProps } = props;

  const EntityDataGrid = () => {
    const data = useSelector(dataSelector);
    const status = useSelector(statusSelector);
    const dispatch = useDispatch();

    useEffect(() => {
      if(!fetchActions) {
        return;
      }

      if (Array.isArray(fetchActions)) {
        fetchActions.forEach((action) => dispatch(action()));
      } else {
        dispatch(fetchActions());
      }
    }, [dispatch]);

    if (status === 'loading') {
      return (
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <DataGrid
        rows={data}
        localeText={GRID_LOCALE_HR}
        columns={columns}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={50}
        {...otherProps}
      />
    );
  };

  return EntityDataGrid;
};

export default withEntityDataGrid;
