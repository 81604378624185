import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Autocomplete, Box, Button, CircularProgress, MenuItem, Stack, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import uniq from 'lodash/uniq';
import {chartConfigPushAgg, chartConfigPopAgg, chartConfigChangeAgg} from '../store/chartConfig.js';


const aggregations = [
  {id: 'avg', name: 'Prosjek'},
  {id: 'sum', name: 'Zbroj'},
  {id: 'min', name: 'Minimum'},
  {id: 'max', name: 'Maksimum'}
];


const AggInput = (props) => {

  const detectionPhenomena = useSelector((state) => state.detectionPhenomena.data);
  const phenomenaStatus = useSelector((state) => state.detectionPhenomena.status);
  const seriesState = useSelector((state) => state.chartConfig.aggSeries[props.index]);

  const [aggregation, setAggregation] = useState('avg');

  const [selectionFilter, setFilter] = useState({});

  const dispatch = useDispatch();

  const handleAggSelect = (event) => {
    setAggregation(event.target.value);
    dispatch(chartConfigChangeAgg({index: props.index, key: 'agg_func', value: event.target.value}));
  };

  const handlePhenomenaSelect = useCallback((val) => {
    dispatch(chartConfigChangeAgg({index: props.index, key: 'class_name', value: val}));
  }, [dispatch, props.index]);

  const phenomenaSelectionList = useMemo(() => {
    return detectionPhenomena.filter((p)=>{
      let exclude = false;
      Object.keys(selectionFilter).every((key) => {
        if (selectionFilter[key]) {
          if (p[key] !== selectionFilter[key]) {
            exclude = true;
            return false;
          }
        }
        return true;
      });
      return !exclude;
    });
  }, [detectionPhenomena, selectionFilter]);

  const selectedValue = useMemo(() => {
    if(seriesState?.class_name){
      return phenomenaSelectionList.find(p => p.id === seriesState.class_name) || null;
    }
    return phenomenaSelectionList[0] || null;
  }, [phenomenaSelectionList, seriesState?.class_name]);


  if (phenomenaStatus === 'loading') {
    return <Box sx={{display: 'flex'}}>
      <CircularProgress/>
    </Box>;
  }

  return <Box component="form" sx={{
    display: 'flex',
    flexDirection: 'row'
  }}>

    <TextField
      id="AggSelect"
      select
      label="Operacija"
      value={aggregation}
      onChange={handleAggSelect}
      sx={{marginRight: '3px'}}
    >
      {aggregations.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>


    <Autocomplete
      renderInput={(params) => <TextField {...params} label="Kategorija" />}
      options={uniq(detectionPhenomena.map((p) => p.category).filter((p) => !!p))}
      onChange={(event, value) => {
        setFilter({...selectionFilter, category: value});
      }}
      style={{ width: 200 }}

    />

    <Autocomplete
      renderInput={(params) => <TextField {...params} label="Analitički model" />}
      options={uniq(detectionPhenomena.map((p) => p.analytic_model).filter((p) => !!p))}
      onChange={(event, value) => {
        setFilter({...selectionFilter, analytic_model: value});
      }}
      style={{ width: 200 }}
    />

    <Autocomplete
      value={selectedValue}
      getOptionLabel={(option) => option.name || option.id}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => <TextField {...params} label="Pojava" />}
      options={phenomenaSelectionList}
      onChange={(e,v) => handlePhenomenaSelect(v?.id)}
      style={{ width: 300 }}
      noOptionsText="Nema Pojave koja odgovara kriterijima"
    />
  </Box>;
};


AggInput.propTypes = {
  index: PropTypes.number.isRequired
};


const AggSeriesInput = () => {

  const dispatch = useDispatch();

  const [index, setIndex] = useState(0);
  const [inputChildren, setInputChildren] = useState([<AggInput key={`agg_input_${index}`} index={index} />]);

  const onAddChild = useCallback(
    () => {
      setInputChildren([...inputChildren,  <AggInput key={`agg_input_${index + 1}`} index={index + 1} />]);
      setIndex(index + 1);
      dispatch(chartConfigPushAgg({agg_func: 'avg', class_name: null}));
    }, [inputChildren, index, dispatch]
  );

  const onRemoveChild = useCallback(
    () => {
      setInputChildren(inputChildren.slice(0, -1));
      setIndex(index - 1);
      dispatch(chartConfigPopAgg());
    }, [inputChildren, index, dispatch]
  );


  return <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>

    <Stack direction="row" spacing={2}>
      {index < 4 && <Button
        variant="outlined"
        onClick={onAddChild}
        sx={{fontSize: '1.1em', fontWeight: 'bold'}}
      >
        +
      </Button>}

      {index > 0 && <Button
        variant="outlined"
        onClick={onRemoveChild}
        sx={{fontSize: '1.1em', fontWeight: 'bold'}}
      >
        -
      </Button>}
    </Stack>

    <Stack direction="column" spacing={2} sx={{marginTop: '20px'}}>
      {inputChildren}
    </Stack>

  </Box>;
};

export default AggSeriesInput;
