import {createSlice} from '@reduxjs/toolkit';

// NOTE: currently just exposes static frontend data, could be used for more later on

const initialState = {
  default: {
    colors: [
      '#4f7694',
      '#b35010',
      '#97882c',
      '#fede95',
      '#73816a'
    ],
    lineWidth: 3,
    pointSize: 4
  }
};

const userDataSlice = createSlice({
  name: 'chartStyles',
  initialState
});

export default userDataSlice.reducer;
