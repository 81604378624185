import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#66b447',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#9547b4',
    },
  },
});

export default theme;
