import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Tab, Box} from '@mui/material';
import {useDispatch} from 'react-redux';

import {chartConfigSetAgg} from '../store/chartConfig.js';
import AggSavedSelect from './AggSavedSelect';
import AggSeriesInput from './AggSeriesInput';
import AggTime from './AggTime.js';


const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const getTabProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};


const AggFullInput = () => {

  const [value, setValue] = useState(0);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{width: '100%'}}>

      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label='Definirane vizualizacije'
            {...getTabProps(0)}
            sx={{fontSize: '1.2em'}}
          />
          <Tab
            label='Nova vizualizacija'
            onClick={() => dispatch(chartConfigSetAgg([{agg_func: 'avg', class_name: 'apple_leaf_healthy'}]))} // TODO not good that this is here
            {...getTabProps(1)}
            sx={{fontSize: '1.2em'}}
          />
        </Tabs>
        <AggTime />
      </Box>
      <TabPanel value={value} index={0}>
        <AggSavedSelect/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AggSeriesInput />
      </TabPanel>

    </Box>
  );
};

export default AggFullInput;
