// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import AppConfig from 'AppConfig';
import store from '../store';
import { userLogout } from 'store/auth';
import Cookies from 'js-cookie';

export async function client(endpoint, {body, ...customConfig} = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  endpoint = endpoint.replace(/^\/+/, '').replace(/\/$/, ''); // trim slashes


  const csrf_token = Cookies.get('csrf_access_token');
  if(csrf_token) {
    //when sendiong authorization token using http cookie, we need to send csrf token as well
    headers['X-CSRF-TOKEN'] = csrf_token;
  }
  //Alternative version of sending authorization token using http header will be used for mobile app
  // if (authState.userToken){
  //   // warning: never use this client for an external request
  //   headers['Authorization'] = `Bearer ${authState.userToken}`;
  // }




  const config = {
    method: body ? 'POST' : 'GET',
    mode: process.env.NODE_ENV === 'development' ? 'cors' : 'same-origin',
    credentials: 'include',

    body: body ? typeof body === 'string' ? body : JSON.stringify(body) : undefined,

    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };


  try {
    const result = await fetch(AppConfig.backendUrl + '/' + endpoint, config);
    const response = await result.json();
    if (result.ok) {
      //TODO: improve non-json handling
      return response;
    }

    if(result.status === 401 && store.getState().auth.user?.id) {
      store.dispatch(userLogout());
    }

    // noinspection ExceptionCaughtLocallyJS
    throw new Error(response?.message || result.statusText);
  } catch (err) {
    return Promise.reject(err);
  }
}

client.get = async function (endpoint, customConfig = {}) {
  return client(endpoint, {...customConfig, method: 'GET'});
};

client.post = async function (endpoint, body, customConfig = {}) {
  return client(endpoint, {...customConfig, body});
};
