/* eslint-disable no-restricted-globals */

let AppConfig = {

  //dev config assumes react server is running on default port (3000) and flask backend is on 5000
  backendUrl: process.env.NODE_ENV === 'development'
    ? location.protocol+'//'+location.host.replace(':3000' ,':5000')
    : location.protocol+'//'+location.host,

};

export default AppConfig;
