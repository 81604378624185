import {createSlice} from '@reduxjs/toolkit';
import {dateToStandardString, oneMonthAgo} from '../util/timeUtils';

const initialState = {
  filter: [
    {type: 'minPointTime', value: dateToStandardString(oneMonthAgo)},
    {type: 'maxPointTime', value: dateToStandardString(new Date())},
  ],
  sort: [],
  status: 'idle'
};


const detectionFilterSlice = createSlice({
  name: 'detectionFilter',
  initialState,
  reducers: {
    // changes to state may happen in createSlice
    detectionFilterClear(state, action) {
      state.filter = [];
    },
    // sets or adds a filter condition, payload is object {type: name, value: value}
    // if type is already present, changes the value for that type, otherwise adds condition to filter
    detectionFilterSet(state, action) {
      const i = state.filter.findIndex(e => e.type === action.payload.type);
      if (i > -1) {
        state.filter[i].value = action.payload.value;
      } else {
        state.filter.push(action.payload);
      }
    },
    // removes a filter of the type if present, payload is string type
    detectionFilterRemove(state, action) {
      const i = state.filter.findIndex(e => e.type === action.payload);
      if (i > -1) {
        state.filter.splice(i, 1);
      }
    },
    // remove all sort conditions
    detectionSortClear(state, action) {
      state.sort = [];
    },
    // sets a single sort condition, payload is object {'column': str, 'order': 'asc'/'desc'}
    detectionSortSetSingle(state, action) {
      state.sort = [action.payload];
    }

  }
});


export default detectionFilterSlice.reducer;
export const {
  detectionFilterClear,
  detectionFilterSet,
  detectionFilterRemove,
  detectionSortClear,
  detectionSortSetSingle
} = detectionFilterSlice.actions;
