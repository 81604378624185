import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AdvTabs from 'components/common/AdvTabs';
import UsersManagement from 'components/settings/UsersManagement';
import { TabContext, TabPanel } from '@mui/lab';
import OrganizationsManagement from 'components/settings/OrganizationsManagement';
import SitesManagement from 'components/settings/SitesManagement';
import ParcelsManagement from 'components/settings/ParcelsManagement';
import RoversManagement from 'components/settings/RoversManagement';
import SensorsManagement from 'components/settings/SensorsManagement';

const Settings = () => {

  const [tabValues, setTabValues] = useState([]);

  const {section} = useParams();

  const navigate = useNavigate();
  const currentPath = useLocation();

  const handleChange = useCallback((event, newValue) => {
    if(!section) {
      navigate(currentPath.pathname + newValue);
    } else {
      const parentPath = currentPath.pathname.replace(/^(.*\/)[^/]+$/, '$1');
      navigate(`${parentPath}${newValue}`);
    }
  }, [currentPath.pathname, navigate, section]);

  useEffect(() => {
    if(!section) {
      navigate(currentPath.pathname + '/users');
    } else {
      const parentPath = currentPath.pathname.replace(/^(.*\/)[^/]+$/, '$1');
      if(!tabValues.includes(section)) {
        navigate(`${parentPath}users`);
      }
    }
  }, [section, navigate, currentPath.pathname, tabValues]);

  return <Box>
    <h1>Postavke</h1>
    <Box>
      <TabContext value={section}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AdvTabs
            onTabValuesChange={setTabValues}
            onChange={handleChange}
            value={section}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Korisnici" value={'users'} />
            <Tab label="Organizacije" value={'organizations'} />
            <Tab label="Lokacije" value={'locations'} />
            <Tab label="Voćnjaci" value={'parcels'} />
            <Tab label="Roveri" value={'rovers'} />
            <Tab label="Kamere/Senzori" value={'sensors'} />

          </AdvTabs>
        </Box>

        <TabPanel value={'users'} sx={{p: 1}}>
          <UsersManagement />
        </TabPanel>
        <TabPanel value={'organizations'} sx={{p: 1}}>
          <OrganizationsManagement />
        </TabPanel>
        <TabPanel value={'locations'} sx={{p: 1}}>
          <SitesManagement />
        </TabPanel>
        <TabPanel value={'parcels'} sx={{p: 1}}>
          <ParcelsManagement />
        </TabPanel>
        <TabPanel value={'rovers'} sx={{p: 1}}>
          <RoversManagement />
        </TabPanel>
        <TabPanel value={'sensors'} sx={{p: 1}}>
          <SensorsManagement />
        </TabPanel>

      </TabContext>
    </Box>

  </Box>;
};

export default Settings;
