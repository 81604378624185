import React, {useState} from 'react';
import locale from 'date-fns/locale/hr'; // used for date pickers
// import locale from 'date-fns/locale/de';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {Box, MenuItem, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import {chartConfigSetTimePart} from '../store/chartConfig.js';
import {dateToStandardString} from '../util/timeUtils.js';
import DateFromToPicker from './common/DateFromToPicker.js';


const intervals = [
  {value: 'day', label: 'Dan'},
  {value: 'week', label: 'Tjedan'},
  {value: 'month', label: 'Mjesec'}
];


const AggTime = () => {

  const dispatch = useDispatch();

  const aggTime = useSelector((state) => state.chartConfig.time);

  const [interval, setInterval] = useState(aggTime.interval);


  return <LocalizationProvider
    dateAdapter={AdapterDateFns}
    adapterLocale={locale}
    localeText={{todayButtonLabel: 'Danas', cancelButtonLabel: 'Odustani', okButtonLabel : 'Prihvati'}}
  >

    <Box component="form" sx={{
      display: 'flex',
      flexDirection: 'row',
      marginTop: '20px',
      marginBottom: '10px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }}>

      <DateFromToPicker
        valueFrom={aggTime.date_from}
        valueTo={aggTime.date_to}
        onChangeFrom={(newValue) => {
          dispatch(chartConfigSetTimePart({key: 'date_from', value: dateToStandardString(newValue)}));
        }}
        onChangeTo={(newValue) => {
          dispatch(chartConfigSetTimePart({key: 'date_to', value: dateToStandardString(newValue)}));
        }}
      />

      <TextField
        id="IntervalSelect"
        select
        label="Interval"
        value={interval}
        onChange={(event) => {
          setInterval(event.target.value);
          dispatch(chartConfigSetTimePart({key: 'interval', value: event.target.value}));
        }}
        sx={{width: '200px'}}
      >
        {intervals.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </Box>

  </LocalizationProvider>;
};

export default AggTime;
