import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from '../util/apiClient';

const createBaseRemoteStore = (entityName, options) => {
  if(!options || !entityName) {
    throw new Error('Base store factory "entityName" and "options" must be provided!');
  }

  const initialState = {
    data: options.initalData || [],
    idToLabel: options.idToLabel ? {...options.idToLabel} : {},
    status: options.startingstatus || 'init',
  };

  const fetchData = createAsyncThunk(`${entityName}/list`, async () => {
    const response = await client.get(options.fetchUrl || `${entityName}/list`);
    return response.data;
  });

  const slice = createSlice({
    name: entityName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          state.data = action.payload;

          const idToLabel = {};
          for (const d of action.payload) {
            idToLabel[d.id] = d.name;
          }
          state.idToLabel = idToLabel;

          state.status = 'ok';
        })
        .addCase(fetchData.rejected, (state, action) => {
          state.data = [];
          state.idToLabel = {};
          state.status = 'error';
        });

      if(options.onFulfilled) {
        builder.addCase(fetchData.fulfilled, options.onFulfilled);
      }
      if(options.onRejected) {
        builder.addCase(fetchData.rejected, options.onRejected);
      }
      if(options.onPending) {
        builder.addCase(fetchData.pending, options.onPending);
      }
    },
  });

  return {
    reducer: slice.reducer,
    fetchData,
  };
};

export default createBaseRemoteStore;