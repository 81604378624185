import React, {useMemo, useState} from 'react';
import {Paper, Typography, Container, Button, Box, CircularProgress} from '@mui/material';
import {
  VictoryChart, VictoryAxis, VictoryTheme, VictoryLabel, VictoryLegend, VictoryTooltip,
  VictoryLine, VictoryScatter
} from 'victory';
import {format, isValid} from 'date-fns';
import {useSelector} from 'react-redux';

import AggFullInput from '../components/AggFullInput';
import {client} from '../util/apiClient';


const Dashboard = () => {

  const aggSeries = useSelector((state) => state.chartConfig.aggSeries);
  const aggTime = useSelector((state) => state.chartConfig.time);

  const idToLabel = useSelector((state) => state.detectionPhenomena.idToLabel);
  const chartStyle = useSelector((state) => state.chartStyles.default);

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [legendData, setLegendData] = useState(null);

  /***
   * The function that gets the aggregation data needed for the chart.
   * @returns {Promise<void>}
   */
  const onDrawChart = async () => {  // TODO handle errors properly, update docstring
    setIsLoading(true);
    if (aggSeries?.length) {
      const newData = [];
      const newLegendData = [];
      for (let i = 0; i < aggSeries.length; i++) {

        const response = await client.post(
          'detections/aggregate',
          {agg_column: 'value', ...aggSeries[i], ...aggTime}
        );
        if (!response.success) {
          setIsLoading(false);
          return;
        }

        newData.push(response.data);

        newLegendData.push({
          name: aggSeries[i].legend || idToLabel[aggSeries[i].class_name] || aggSeries[i].class_name,
          symbol: {fill: chartStyle.colors[i]}
        });

      }
      setData(newData);
      setLegendData(newLegendData);
    }
    setIsLoading(false);
  };

  const isValidTimes = useMemo(() => {
    // quick hack - if a date is invalid it is stored in store as false
    return aggTime.date_from && aggTime.date_to;
  }, [aggTime]);


  let inner;
  if (isLoading) {
    inner = <Box sx={{display: 'flex', justifyContent: 'center'}}>
      <CircularProgress/>
    </Box>;

  } else {
    if (data?.length > 0) {
      inner = <>
        <VictoryChart
          domainPadding={20} // prevent it from overlapping the axis
          theme={VictoryTheme.material}
          width={800}
          padding={{
            bottom: 60,
            left: 70,
            right: 10,
            top: 20
          }} // TODO left padding and x legend position could depend on n digits on y
        >

          <VictoryAxis tickLabelComponent={<VictoryLabel angle={-45} textAnchor='end'/>} fixLabelOverlap/>
          <VictoryAxis dependentAxis/>

          {/*main chart*/}
          {data.map((series, i) => {
            return <VictoryLine
              key={`chart_series_${i}`}
              data={series}
              style={{data: {stroke: chartStyle.colors[i], strokeWidth: chartStyle.lineWidth}}}
              x={(v) => format(new Date(v.date), 'dd.MM.yy')}
              y={(v) => parseFloat(v.agg_value)}
            />;
          })}

          {/*line points to allow mouseover tooltip data*/}
          {data.map((series, i) => {
            return <VictoryScatter
              key={`chart_series_${i}`}
              data={series}
              style={{data: {fill: chartStyle.colors[i]}}}
              size={chartStyle.pointSize}
              x={(v) => format(new Date(v.date), 'dd.MM.yy')}
              y={(v) => parseFloat(v.agg_value)}
              // label={}
              labels={({datum}) => `y: ${parseFloat(datum.agg_value).toFixed(1)}\n \nx: ${datum.xName}`}
              labelComponent={<VictoryTooltip/>}
            />;
          })}

          <VictoryLegend
            x={80}
            y={0}
            orientation="horizontal"
            gutter={20}
            data={legendData}
          />

        </VictoryChart>
      </>;
    } else {
      inner = <Typography sx={{
        fontSize: '1.2em',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px'
      }}>{'Odaberite podatke za vizualizaciju i kliknite "Izradi grafikon"'}.</Typography>;
    }
  }


  // noinspection RequiredAttributes
  return <Paper>
    <Typography variant="h4">Vizualizacija podataka</Typography>

    <Container sx={{paddingBottom: '100px', alignItems: 'center', justifyContent: 'center'}}>

      <AggFullInput/>
      <Box sx={{display: 'flex', paddingBottom: '20px', alignItems: 'center', justifyContent: 'center'}}>
        <Button
          onClick={onDrawChart}
          variant="contained"
          disabled={isLoading || !isValidTimes}
          sx={{fontSize: '1.2em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          Izradi grafikon
        </Button>
      </Box>

      {inner}

    </Container>

  </Paper>;

};

export default Dashboard;
