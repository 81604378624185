import React from 'react';
import { Box } from '@mui/material';
import { fetchSettingsOrganizations } from 'store/settingsOrganizations';
import withEntityDataGrid from 'components/common/withEntityDataGrid';

const columns = [
  {
    field: 'display_name',
    headerName: 'Naziv',
    width: 150,
    editable: false,
  },
  {
    field: 'company_name',
    headerName: 'Ime tvrtke',
    width: 150,
    editable: false,
  },
  {
    field: 'address',
    headerName: 'Adresa',
    width: 200,
    editable: false,
  },
];

const dataSelector = (state) => state.settingsOrganizations.data;
const statusSelector = (state) => state.settingsLocations.status;

const config = {
  columns,
  fetchActions: fetchSettingsOrganizations,
  dataSelector,
  statusSelector,
};

const OrganizationsGrid = withEntityDataGrid(config);

const OrganizationsManagement = props => {
  return <Box height={800}>
    <OrganizationsGrid {...props} />
  </Box>;
};

export default OrganizationsManagement;