import {createSlice} from '@reduxjs/toolkit';
import {dateToStandardString, oneMonthAgo} from '../util/timeUtils';

// TODO currently CHART config, but will be reworked to facilitate data queries in more general way (e.g. for tables)

const initialState = {
  aggSeries: [],
  time: {
    'date_from': dateToStandardString(oneMonthAgo), // false if user sets invalid date
    'date_to': dateToStandardString(new Date()), // false if user sets invalid date
    'interval': 'week'
  },
  status: 'idle'
};


const chartConfigSlice = createSlice({
  name: 'chartConfig',
  initialState,
  reducers: {
    // changes to state may happen in createSlice
    chartConfigSetAgg(state, action) {
      state.aggSeries = action.payload;
    },
    chartConfigPushAgg(state, action) {
      state.aggSeries.push(action.payload);
    },
    chartConfigPopAgg(state, action) {
      state.aggSeries.pop();
    },
    chartConfigChangeAgg(state, action) {
      state.aggSeries[action.payload.index][action.payload.key] = action.payload.value;
    },
    chartConfigSetTimePart(state, action) {
      state.time[action.payload.key] = action.payload.value;
    }
  }
});


export default chartConfigSlice.reducer;
export const {
  chartConfigSetAgg,
  chartConfigPushAgg,
  chartConfigPopAgg,
  chartConfigChangeAgg,
  chartConfigSetTimePart
} = chartConfigSlice.actions;
