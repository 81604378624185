
import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/hr';
import PropTypes from 'prop-types';

const DateFromToPicker = ({...props}) => {

  const [dateStart, setDateStart] = useState(props.valueFrom);
  const [dateEnd, setDateEnd] = useState(props.valueTo);

  useEffect(() => {
    props.onChange(dateStart, dateEnd);
  }, [dateStart, dateEnd, props]);

  return <Box sx={{
    display: 'flex',
    flexDirection: 'row'

    //TODO: design and spacing ?!? maybe switch to mui-grid

  }}>
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={{todayButtonLabel: 'Danas', cancelButtonLabel: 'Odustani', okButtonLabel : 'Prihvati'}}
    >
      <DatePicker
        value={dateStart}
        label="Od"
        inputFormat='dd.MM.yyyy'
        onChange={(newValue) => {
          setDateStart(newValue);
          props.onChangeFrom(newValue);
        }}
        inputProps={{
          placeholder: 'dd.mm.gggg'
        }}
        renderInput={(params) => <TextField {...params} sx={{width: '200px'}}
          onFocus={event => {
            event.target.select(); //autselect content on click
          }}
        />}
        componentsProps={{
          actionBar: { actions: ['today', 'accept', 'cancel']},
        }}
      />

      <DatePicker
        label="Do"
        inputFormat='dd.MM.yyyy'
        value={dateEnd}
        onChange={(newValue) => {
          setDateEnd(newValue);
          props.onChangeTo(newValue);
        }}
        inputProps={{
          placeholder: 'dd.mm.gggg'
        }}
        renderInput={(params) => <TextField {...params}  sx={{width: '200px'}}
          onFocus={event => {
            event.target.select(); //autselect content on click
          }}
        />}
        componentsProps={{
          actionBar: { actions: ['today', 'accept', 'cancel']},
        }}
      />
    </LocalizationProvider>
  </Box>;
};

DateFromToPicker.propTypes = {
  onChangeFrom: PropTypes.func,
  onChangeTo: PropTypes.func,
  valueFrom: PropTypes.string,
  valueTo: PropTypes.string,
  onChange: PropTypes.func,
};

DateFromToPicker.defaultProps = {
  onChangeFrom: () => {},
  onChangeTo: () => {},
  onChange: () => {},
  valueFrom: '',
  valueTo: '',
};


export default DateFromToPicker;
