import Dashboard from 'modules/Dashboard';
import DetectionsList from 'modules/DetectionsList';
import DataList from 'modules/DataList';
import AlarmList from 'modules/AlarmList';
import AlarmForm from 'components/AlarmForm';
import React, { useEffect } from 'react';
import {
  FaChartLine,
  FaDatabase,
  FaTable,
  FaClock,
  FaCogs
} from 'react-icons/fa';
import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from './layouts/main';
import SimpleLayout from './layouts/simple';

// import UserPage from './pages/UserPage';
import LoginPage from './pages/Login';
import Page404 from './pages/Page404';
import { useSelector } from 'react-redux';
import ProtectedRoute from 'components/Helper/ProtectedRoute';
import { preloadDataAuthenticated } from './store';
import Settings from 'modules/Settings';


// ----------------------------------------------------------------------

export const navMenuConfig = [
  {
    title: 'Vizualizacije',
    path: '/app',
    icon: <FaChartLine />,
  },
  {
    title: 'Mjerenja',
    path: '/data',
    icon: <FaDatabase />,

  },
  {

    title: 'Alarm',
    path: '/alarm/list',
    icon: <FaClock/>,
  },
  {
    title: 'Opis pojava',
    path: '/list',
    icon: <FaTable />,
  },
  {
    title: 'Postavke sustava',
    path: '/settings',
    icon: <FaCogs />,
  },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: <FaLockOpen />,
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: <FaNotEqual />,
  // },
];


const Router = () => {

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user.id){
      preloadDataAuthenticated();
    }
  }, [user?.id]);


  return useRoutes([
    {
      path: 'login',
      element: <LoginPage/>,
    },
    {
      path: '/',
      element: <ProtectedRoute><MainLayout/></ProtectedRoute>,
      children: [
        {element: <Navigate to="/app"/>, index: true},
        {path: 'app', element: <Dashboard/>},
        {path: 'data', element: <DataList/>},
        {path: 'list', element: <DetectionsList/>},
        {path: 'alarm/list', element: <AlarmList/>},
        {path: 'alarm/new', element: <AlarmForm/>},
        {path: 'settings/:section?', element: <Settings />},
        // { path: 'blog', element: <BlogPage /> },
      ]
    },
    {
      element: <SimpleLayout/>,
      children: [
        {path: '404', element: <Page404/>},
        //redirect all unknown routes to 404
        {path: '*', element: <Navigate to="/404" replace />},
      ],
    }
  ]);
};



export default Router;
