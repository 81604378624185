import React, { useEffect } from 'react';
import {Box, CircularProgress} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';

import {useDispatch, useSelector} from 'react-redux';
import {GRID_LOCALE_HR} from '../locale/dataGridLocaleHR.js';
import { fetchDetectionPhenomena } from '../store/detectionPhenomena';


const DetectionsList = () => {

  const detectionPhenomena = useSelector((state) => state.detectionPhenomena.data);
  const phenomenaStatus = useSelector((state) => state.detectionPhenomena.status);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDetectionPhenomena())
  }, [dispatch]);

  if (phenomenaStatus === 'loading') {
    return <Box sx={{display: 'flex'}}>
      <CircularProgress />
    </Box>;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 200
    },
    {
      field: 'name',
      headerName: 'Naziv',
      width: 250,
      editable: false,
    },
    {
      field: 'analytic_model',
      headerName: 'Analitički model',
      width: 250,
      editable: false,
    },
    {
      field: 'category',
      headerName: 'Kategorija',
      width: 250,
      editable: false,
    }
  ];

  return <Box height={800}>
    <DataGrid
      components={{
        Toolbar: GridToolbar,
      }}
      rows={detectionPhenomena}
      localeText={GRID_LOCALE_HR}
      columns={columns}
      rowsPerPageOptions={[10, 20, 50]}
      pageSize={50}
    />

  </Box>;
};


export default DetectionsList;
